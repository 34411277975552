<template>
  <h2>Terms and conditions for 7founders.</h2>
</template>

<script>
export default {
  name: "TermsAndConditions",
};
</script>

<style scoped></style>
